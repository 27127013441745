// src/components/NewsletterPopup.js
import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import popup from "../components/media/banner/newsletter-popup.jpg";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

Modal.setAppElement("#root");

const NewsletterPopup = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const alreadySubscribed = localStorage.getItem("subscribed");
    if (!alreadySubscribed) {
      setIsOpen(true);
    }
  }, []);

  const closeModal = () => {
    setIsOpen(false);
    localStorage.setItem("subscribed", "true");
  };

  // Reset the subscribed flag when the page is refreshed
  useEffect(() => {
    const handleBeforeUnload = () => {
      localStorage.removeItem("subscribed");
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
    };
  }, []);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={closeModal}
      contentLabel="Subscribe to Newsletter"
    >
      <div className="popup-shadow" />
      <div className="newsletter-popup">
        <a to="#" className="newsletter-close" onClick={closeModal} />
        <div className="newsletter-container">
          <div className="newsletter-img">
            <img src={popup} alt />
          </div>
          <div className="newsletter-form">
            <form action="#" method="post">
              <div className="newsletter-title">
                <div className="title">
                  Get the
                  <br /> best designs
                </div>
                <div className="sub-title">Locate the nearest ZIYA store</div>
              </div>
              <div
                className="newsletter-input clearfix"
                style={{ placeContent: "center" }}
              >
                <Link to="/find-my-ziya">
                  <Button
                    type="submit"
                    defaultValue="Store Location"
                    className="btn btn-lg"
                    style={{
                      backgroundColor: "black",
                      fontSize: "18px",
                      color: "white",
                    }}
                  >
                    Store
                  </Button>
                </Link>
              </div>
              <div className="newsletter-no">
                <Button
                  type="button"
                  onClick={closeModal}
                  style={{
                    border: "none",
                    background: "transparent",
                    color: "inherit",
                  }}
                >
                  no thanks !
                </Button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default NewsletterPopup;
