import React, { useEffect, useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import image1Hovered from "../assets/media/ziya-products/product1-2.jpg"; // Add the hovered image for product1
import image1 from "../assets/media/ziya-products/product1.jpg";
import image2Hovered from "../assets/media/ziya-products/product2-2.jpg"; // Add the hovered image for product2
import image2 from "../assets/media/ziya-products/product2.jpg";
import image3Hovered from "../assets/media/ziya-products/product3-2.jpg"; // Add the hovered image for product3
import image3 from "../assets/media/ziya-products/product3.jpg";
import image4Hovered from "../assets/media/ziya-products/product4-2.jpg"; // Add the hovered image for product4
import image4 from "../assets/media/ziya-products/product4.jpg";
import image5Hovered from "../assets/media/ziya-products/product5-2.jpg"; // Add the hovered image for product5
import image5 from "../assets/media/ziya-products/product5.jpg";
import { listTopProducts } from "../functions/TopProducts/TopProducts";
import { listCategoryProducts } from "../functions/ProductDetails/ProductCategory";
const SliderPart = () => {
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [hoveredIconIndex, setHoveredIconIndex] = useState(null);

  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow trend-next-arrow" onClick={onClick}>
      <AiOutlineRight />
    </div>
  );

  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow trend-prev-arrow" onClick={onClick}>
      <AiOutlineLeft />
    </div>
  );

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Display 4 products at a time
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 769,
        settings: {
          slidesToShow: 3,
        },
      },
      {
        breakpoint: 525,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 3,
        },
      },
    ],
  };

  const handleImageHover = (index) => {
    setHoveredImageIndex(index);
    setHoveredIconIndex(null);
  };

  const handleIconHover = (index) => {
    setHoveredIconIndex(index);
  };

  const [products, setProducts] = useState([]);

  useEffect(() => {
    loadTrendingProducts();
  }, []);

  // const loadTrendingProducts = () => {
  //   listTopProducts().then((res) => {
  //     console.log("trending", res);
  //     setProducts(res.data);
  //   });
  // };

  const loadTrendingProducts = () => {
    listCategoryProducts().then((res) => {
      console.log("trending", res);
      const trendingProducts = res.data.filter(
        (product) => product.IsTopProduct === true
      );

      setProducts(trendingProducts);
    });
  };
  const imageSources = [
    [image1, image1Hovered], // Pair of images for product1
    [image2, image2Hovered], // Pair of images for product2
    [image3, image3Hovered], // Pair of images for product3
    [image4, image4Hovered], // Pair of images for product4
    [image5, image5Hovered], // Pair of images for product5
  ];

  return (
    <div className="slider-container">
      <div className="block-title">
        <h2 className="text-center fs-1">Trending Products</h2>
      </div>
      <Slider {...settings} className="">
        {products.map((product, index) => (
          <div
            className="product-item position-relative"
            key={index}
            style={{ width: "350px" }}
          >
            <div
              className={`product-image ${
                hoveredImageIndex === index ? "hovered" : ""
              }`}
              onMouseEnter={() => handleImageHover(index)}
              onMouseLeave={() => handleImageHover(null)}
            >
              <img
                // width="500px"
                // height="400px"
                className="slick-img "
                src={
                  hoveredImageIndex === index
                    ? `${process.env.REACT_APP_API_URL_ZIYA}/${product.ProductHoverImage}`
                    : `${process.env.REACT_APP_API_URL_ZIYA}/${product.ProductImage}`
                }
                alt={`Image ${index + 1}`}
                style={{ width: "100%", height: "100%" }}
              />
              {hoveredImageIndex === index && (
                <div className="product-button"></div>
              )}
            </div>
            {hoveredIconIndex === index && (
              <div className="quickview-text">Quick View</div>
            )}
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default SliderPart;
