import axios from "axios";

export const createContactUs = async (values) => {
  return await axios.post(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/contact-create`,
    values,
    {
      headers: {
        "Content-Type": "application/json",
      },
    }
  );
};
