import axios from "axios";

export const listCategoryProducts = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/list-category-products`
  );
};

export const FilterProductByWeight = async (minWeight, maxWeight) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/filter-products-weight/${minWeight}/${maxWeight}`
  );
};
