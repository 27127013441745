import * as faceapi from "face-api.js";
import React, { useEffect, useRef, useState } from "react";
import "../../App.css";

import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getTryJewelByProduct } from "../../functions/TryOn/TryJewel";

let prevX = null;
let prevY = null;

const TryCardModel = ({ tryJewel, isTry, setIsTry, tryProductId }) => {
  const videoRef = useRef();
  const canvasRef = useRef();

  const [product, setProduct] = useState(false);
  const [imageLoading, setImageLoading] = useState(true);

  const [values, setValues] = useState({
    scaleFactor: 1.1,
    x: 0,
    y: 0,
  });

  useEffect(() => {
    if (product) {
      prevX = null;
      setValues({
        ...values,
        x: product.x,
        y: product.y,
        scaleFactor: product.scaleFactor,
      });
    }
  }, [product]);

  // LOAD FROM USEEFFECT
  useEffect(() => {
    if (isTry) {
      startVideo();
      // videoRef.current.onloadedmetadata = () => setVideoLoaded(true);
      loadModels();
    }
  }, [isTry]);

  useEffect(() => {
    loadTryJewel();
  }, [tryProductId]);

  const loadTryJewel = () => {
    getTryJewelByProduct(tryProductId).then((res) => {
      setProduct(res.data);
    });
  };

  // OPEN YOU FACE WEBCAM
  const startVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((currentStream) => {
        videoRef.current.srcObject = currentStream;
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const loadModels = () => {
    Promise.all([
      // THIS FOR FACE DETECT AND LOAD FROM YOU PUBLIC/MODELS DIRECTORY

      faceapi.nets.tinyFaceDetector.loadFromUri(
        `${process.env.REACT_APP_API_URL_ZIYA}/uploads/models`
      ),
      faceapi.nets.faceLandmark68Net.loadFromUri(
        `${process.env.REACT_APP_API_URL_ZIYA}/uploads/models`
      ),

      // faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
      // faceapi.nets.faceExpressionNet.loadFromUri("/models")
    ]).then(() => {
      faceMyDetect(product);
    });
  };

  const faceMyDetectOLD = async (product, val) => {
    // if (!videoLoaded) return;
    // setInterval(async () => {
    const detections = await faceapi
      .detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks();
    // console.log(JSON.stringify(detections));

    const video = document.querySelector("video");
    // console.log("video width", video.videoWidth);
    // console.log("video height", video.videoHeight);
    let videoWidth = video.videoWidth;
    let videoHeight = video.videoHeight;

    // DRAW YOU FACE IN WEBCAM
    // canvasRef.current.innerHtml = faceapi.createCanvasFromMedia(
    //   videoRef.current
    // );

    try {
      const resized = faceapi.resizeResults(detections, {
        width: videoWidth,
        height: videoHeight,
      });

      // console.log("resized", JSON.stringify(resized));
      console.log("resized", resized[0].detection._box);
      console.log("scale factor", val, val.scaleFactor, val.x, val.y);

      let width = resized[0].detection._box._width * val.scaleFactor;
      let height = resized[0].detection._box._height * val.scaleFactor;

      // let x = resized[0].detection._box._x - x1;
      // let y = resized[0].detection._box._y + height - y1;

      let x1 = width * val.x;
      let y1 = height * val.y;

      let x2 = resized[0].detection._box._x - x1;
      let y2 = resized[0].detection._box._y + height - y1;

      // draw img to canvasRef
      const canvas = canvasRef.current;
      canvas.width = 540;
      canvas.height = 400;
      const ctx = canvas.getContext("2d");

      // console.log("canvass",canvas);

      const image = new Image();
      image.src = `${process.env.REACT_APP_API_URL_ZIYA}/${product.productImage}`;

      image.onload = () => {
        faceapi.matchDimensions(canvasRef.current, {
          width: videoWidth,
          height: videoHeight,
        });
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        // console.log("canvas",canvas);
        // ctx.clearRect(0, 0, 540, 400);
        ctx.drawImage(image, x2, y2, width, height);
        setImageLoading(false);
      };
    } catch (err) {
      // console.log(err);
      faceapi.matchDimensions(canvasRef.current, {
        width: videoWidth,
        height: videoHeight,
      });
    }

    // faceapi.draw.drawDetections(canvasRef.current, resized);
    // faceapi.draw.drawFaceLandmarks(canvasRef.current,resized)
    // faceapi.draw.drawFaceExpressions(canvasRef.current,resized)
    // }, 100);
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      // Your code logic here
      await faceMyDetect(product, values);
    }, 15); // Adjust the interval time as needed (in milliseconds)

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [product, values]);

  const handleChangeX = (e) => {
    prevX = null;
    setValues({ ...values, x: e.target.value });
  };

  const handleChangeY = (e) => {
    prevX = null;
    setValues({ ...values, y: e.target.value });
  };

  const handleChangeSF = (e) => {
    prevX = null;
    setValues({ ...values, scaleFactor: e.target.value });
  };

  // new code
  const checkDifference = (prevX, prevY, x, y, percentage) => {
    if (prevX === null) {
      return true;
    }
    if (
      prevX < x * (1 + percentage / 100) &&
      prevX > x * (1 - percentage / 100) &&
      prevY < y * (1 + percentage / 100) &&
      prevY > y * (1 - percentage / 100)
    ) {
      return false;
    }
    return true;
  };

  const faceMyDetect = async (product, val) => {
    // setInterval(async () => {
    const detections = await faceapi
      .detectAllFaces(videoRef.current, new faceapi.TinyFaceDetectorOptions())
      .withFaceLandmarks();
    // console.log(JSON.stringify(detections));

    // log camera width and height
    const video = document.querySelector("video");
    let videoWidth = video.videoWidth;
    let videoHeight = video.videoHeight;

    try {
      const resized = faceapi.resizeResults(detections, {
        width: videoWidth,
        height: videoHeight,
      });

      if (
        checkDifference(
          prevX,
          prevY,
          resized[0].detection._box._x,
          resized[0].detection._box._y,
          3
        )
      ) {
        prevX = resized[0].detection._box._x;
        prevY = resized[0].detection._box._y;
        // console.log("resized", JSON.stringify(resized));
        // console.log("resized", resized[0].detection._box);

        // let scaleFactor = 1.3;
        // let scaleFactor = 1.3;
        // let scaleFactor = 1.5;
        let width = resized[0].detection._box._width * val.scaleFactor;
        let height = resized[0].detection._box._height * val.scaleFactor;
        // console.log(height, width);
        // let x1 = width * 0.15;
        // let y1 = height * 0.55;
        let x1 = width * val.x;
        let y1 = height * val.y;
        // let x1 = width * 0.2;
        // let y1 = height * 0.45;

        let x2 = resized[0].detection._box._x + x1;
        let y2 = resized[0].detection._box._y + height - y1;

        // draw img to canvasRef
        const canvas = canvasRef.current;
        const image = new Image();
        image.src = `${process.env.REACT_APP_API_URL_ZIYA}/${product.productImage}`;

        const ctx = canvas.getContext("2d");
        image.onload = () => {
          faceapi.matchDimensions(canvasRef.current, {
            width: videoWidth,
            height: videoHeight,
          });
          // ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.drawImage(image, x2, y2, width, height);
          setImageLoading(false);
        };
      }
    } catch (err) {
      console.log(err);
      faceapi.matchDimensions(canvasRef.current, {
        width: videoWidth,
        height: videoHeight,
      });
    }

    // }, 25);
  };

  const imageStyle = {
    width: "120px",
    height: "120px",
    cursor: "pointer",
    border: "2px solid black",
    borderRadius: "5px",
    // margin: "10px",
  };

  return (
    <React.Fragment>
      {/* <div className="page-content"> */}
      {/* <Container fluid> */}

      <Modal
        isOpen={isTry}
        toggle={() => {
          setIsTry(!isTry);
        }}
        centered
        size="lg"
      >
        <ModalHeader
          className="bg-light p-3"
          toggle={() => {
            setIsTry(false);
          }}
        >
          Try On
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col lg={2}>
              <img
                style={imageStyle}
                src={`${process.env.REACT_APP_API_URL_ZIYA}/${product.productImage}`}
                alt="Product"
              />
            </Col>

            <Col lg={10}>
              <Row>
                <div className="appvide">
                  <canvas
                    // style={{ position: "absolute" }}
                    ref={canvasRef}
                    // width="540"
                    // height="400"
                    className="appcanvas"
                  />
                  <video
                    className="trayVideoSection"
                    crossOrigin="anonymous"
                    ref={videoRef}
                    autoPlay
                  ></video>
                </div>
              </Row>

              <Row className="mt-2">
                <Col lg={3} className="m-2 ">
                  <label for="customRange2" className="form-label text-dark">
                    Zoom : {values.scaleFactor}
                  </label>
                  <input
                    name="scaleFactor"
                    value={values.scaleFactor}
                    onChange={handleChangeSF}
                    type="range"
                    className="form-range"
                    min="0.5"
                    max="2"
                    step="0.05"
                    id="customRange2"
                  ></input>
                </Col>

                <Col lg={3} className="m-2 ">
                  <label for="customRange4" className="form-label text-dark">
                    X : {values.x}
                  </label>
                  <input
                    name="x"
                    value={values.x}
                    type="range"
                    class="form-range"
                    onChange={handleChangeX}
                    min="-1"
                    max="1"
                    step="0.05"
                    id="customRange4"
                  ></input>
                </Col>

                <Col lg={3} className="m-2 ">
                  <label for="customRange3" className="form-label text-dark">
                    Y : {values.y}
                  </label>
                  <input
                    name="y"
                    value={values.y}
                    type="range"
                    class="form-range text"
                    onChange={handleChangeY}
                    min="-1"
                    max="3"
                    step="0.05"
                    id="customRange3"
                  ></input>
                </Col>
              </Row>
            </Col>
          </Row>

          {imageLoading && (
            // Render your loader here
            <div className="d-flex justify-content-center mt-2">
              <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
              </div>
              <h5 className="p-2">
                {/* Wait for a few seconds.This process might take some time. */}
                Wait for a few seconds to load Jewellery
              </h5>
            </div>
          )}
        </ModalBody>
      </Modal>

      {/* </Container> */}
      {/* </div> */}
    </React.Fragment>
  );
};

export default TryCardModel;
