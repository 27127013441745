import React, { useEffect, useState } from "react";
import { Card, Container } from "react-bootstrap";
import { Puff } from "react-loader-spinner";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { listCity, listCountry } from "../../functions/Locations";
// import Iframe from "./Iframe";
import Form from "react-bootstrap/Form";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import { findZiyaLocation } from "../../functions/ZiyaLocation";
import MyMap from "./VectorMap";

import { Helmet } from "react-helmet";
import ziyaIcon from "../../assets/media/logo/ziya-logo-original.png";
import ProcuctDetislPopupNew from "../modal/ProductDetialsNewPopup";

const Find_My_Ziya = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  const [CountryID, setCountry] = useState();
  const [CityID, setCity] = useState();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [ziyaLocation, setZiyaLocations] = useState([]);

  useEffect(() => {
    loadCountries();
    // loadStates();
    loadCities();
  }, []);

  const loadCountries = () => {
    listCountry().then((res) => {
      // console.log(res.data);
      setCountries(res.data);
    });
  };

  const loadCities = () => {
    listCity().then((res) => {
      setCities(res.data);
      // console.log("city", res.data);
    });
  };

  const handleLocate = () => {
    window.scrollTo({
      top: 300, // Set the vertical scroll position here
      behavior: "smooth",
    });

    findZiyaLocation(CountryID, CityID).then((res) => {
      setZiyaLocations(res.data);
      // console.log("find ziya locations", res.data);
    });
  };
  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Show the button when the user scrolls down 200 pixels
        setShowBackToTopButton(true);
      } else {
        // Hide the button when the user scrolls back to the top
        setShowBackToTopButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const AddressData = [
    {
      title: "ZIYA STORE",
      subTitle: "Kanchan",
      address:
        "51, Thakurbari Road, Aambagan, Sakchi, Jamshedpur - 831001 Jharkhand",
      number: "022 49734976",
      Img: ziyaIcon,
    },
    {
      title: "ZIYA STORE",
      subTitle: "Kanchan",
      address:
        "7, Keshavji Bhawan, Diagonal Road, Bistupur, Jamshedpur - 831001 Jharkhand.",
      number: "022 49734976",
      Img: ziyaIcon,
    },
    {
      title: "ZIYA STORE",
      subTitle: "Khandelwal Jewellers",
      address:
        " ‘ Kubde Heights’ , Rajkamal to Ambadevi road , Gandhi chowk Amravati 444601",
      number: "022 49734976",
      Img: ziyaIcon,
    },
    {
      title: "ZIYA STORE",
      subTitle: "Khandelwal Jewellers",
      address:
        "Khandelwal Jewellers Akola Pvt Ltd KJ3 Square, M G Road,Opp Open Theatre, Akola - 444001 (MH)",
      number: "022 49734976",
      Img: ziyaIcon,
    },
    {
      title: "ZIYA STORE",
      subTitle: "Kanchan",
      address:
        "51, Thakurbari Road, Aambagan, Sakchi, Jamshedpur - 831001 Jharkhand",
      number: "022 49734976",
      Img: ziyaIcon,
    },
    {
      title: "ZIYA STORE",
      subTitle: "Kanchan",
      address:
        " ‘ Kubde Heights’ , Rajkamal to Ambadevi road , Gandhi chowk Amravati 444601",
      number: "022 49734976",
      Img: ziyaIcon,
    },
    {
      title: "ZIYA STORE",
      subTitle: "Kanchan",
      address:
        "Khandelwal Jewellers Akola Pvt Ltd KJ3 Square, M G Road,Opp Open Theatre, Akola 444001",
      number: "022 49734976",
      Img: ziyaIcon,
    },
  ];
  const [showProductDetailsModal, setShowProductDetailsModal] = useState(false);

  useEffect(() => {
    // Simulate a delay of 5 seconds (5000 milliseconds)
    const delay = 3000;
    const timeoutId = setTimeout(() => {
      setShowProductDetailsModal(true);
    }, delay);

    // Clear the timeout to prevent the modal from opening if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);
  return (
    <div>
      <Helmet>
        <title>Find Your Dream Mangalsutra: Ziya Jewellers at Your City</title>
        <meta
          name="description"
          content="Your search for the perfect mangalsutra ends here! Find Ziya Mangalsutra jewelers in your city and embrace exquisite designs for your special moments. Visit now"
        />
        <link rel="canonical" href="https://ziyamangalsutra.com/find-my-ziya" />

        <meta
          name="keywords"
          content="mangalsutra Design, diamond mangalsutra design, Gold mangalsutra"
        />
        {/* <!----======= Seo Meta  =======----> */}
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="author" content="" />
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Your search for the perfect mangalsutra ends here! Find Ziya Mangalsutra jewelers in your city and embrace exquisite designs for your special moments. Visit now"
        />
        <meta
          name="og:title"
          content="Find Your Dream Mangalsutra: Ziya Jewellers at Your City"
        />
        <meta
          name="og:description"
          content="Your search for the perfect mangalsutra ends here! Find Ziya Mangalsutra jewelers in your city and embrace exquisite designs for your special moments. Visit now"
        />
        <meta
          name="og:image"
          content="https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png"
        />
        <meta
          name="og:url"
          content="https://ziyamangalsutra.com/find-my-ziya/"
        />
        <meta name="og:site_name" content="Ziya Mangalsutra" />
        <meta name="og:locale" content="en_IN" />
        <meta name="og:video" content="" />
        <meta name="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Find Your Dream Mangalsutra: Ziya Jewellers at Your City"
        />
        <meta
          name="twitter:description"
          content="Your search for the perfect mangalsutra ends here! Find Ziya Mangalsutra jewelers in your city and embrace exquisite designs for your special moments. Visit now"
        />
        <meta
          name="twitter:image"
          content="https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png"
        />
        <meta name="twitter:site" content="@ziyamangalsutra" />
        <meta name="twitter:creator" content="@ziyamangalsutra" />
        {/* <!----======= Schema Markup Script =======----> */}
        <script
          type="application/ld+json"
          class="yoast-schema-graph yoast-schema-graph--main"
        >
          {`
        {
        "@context":"https://schema.org",
        "@graph":[ {
        "@type":"WebSite",
        "@id":"https://ziyamangalsutra.com/find-my-ziya/#webpage",
        "url":"https://ziyamangalsutra.com/find-my-ziya/",
        "name":"Ziya Mangalsutra",
        "description":"Your search for the perfect mangalsutra ends here! Find Ziya Mangalsutra jewelers in your city and embrace exquisite designs for your special moments. Visit now"
		}
        ,
        {
        "@type":"WebPage",
        "@id":"https://ziyamangalsutra.com/find-my-ziya/#webpage",
        "url":"https://ziyamangalsutra.com/find-my-ziya/",
        "inLanguage":"en-US",
        "name":"Ziya Mangalsutra",
        "isPartOf": {
        "@id": "https://ziyamangalsutra.com/find-my-ziya/"
        }
        ,
        "datePublished":"2017-09-20T09:19:08+00:00",
        "dateModified":"2020-11-25T06:40:11+00:00",
        "description":"Your search for the perfect mangalsutra ends here! Find Ziya Mangalsutra jewelers in your city and embrace exquisite designs for your special moments. Visit now"
        }
        ]
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "http://www.schema.org",
        "@type": "product",
        "name": "Ziya Mangalsutra ",
        "image": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "sku": "IF",
        "mpn": "IF",
        "description": "Your search for the perfect mangalsutra ends here! Find Ziya Mangalsutra jewelers in your city and embrace exquisite designs for your special moments. Visit now",
        "aggregateRating": {"@type": "aggregateRating", "ratingValue": "5", "reviewCount": "1", "bestRating":"5 " }
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "https://schema.org/",
        "@type": "Person",
        "name": "Mr Chetan Thadeshwar",
        "url": "https://ziyamangalsutra.com/find-my-ziya/",
        "image": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "jobTitle": "Proprietor",
        "worksFor": {
        "@type": "Organization",
        "name": "Ziya Mangalsutra"
        }
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "http://www.schema.org",
        "@type": "Organization",
        "name": "Ziya Mangalsutra",
        "url": "https://ziyamangalsutra.com/find-my-ziya/"
        "logo": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "image": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "description": "Your search for the perfect mangalsutra ends here! Find Ziya Mangalsutra jewelers in your city and embrace exquisite designs for your special moments. Visit now",
        "address": {
        "@type": "PostalAddress",
        "streetAddress": "Cotton Exchange Building, B-1, Jewel World, Kalbadevi Rd, opp. Surati hotel, Mumbai, Maharashtra - 400002",
        "addressLocality": "Surati hotel",
        "addressRegion": "Mumbai",
        "addressCountry": "India"
        },
        "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+022 43111222"
        }
        }
        `}
        </script>
      </Helmet>
      {showProductDetailsModal && (
        <>
          {/* Apply a z-index to the modal backdrop */}
          <div style={{ zIndex: 9998 }} />
          <ProcuctDetislPopupNew />
        </>
      )}
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#00BFFF"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Header />
          <div id="site-main" className="site-main">
            <div id="main-content" className="main-content">
              <div id="primary" className="content-area">
                <div
                  id="title"
                  className="page-title page-title-dark title-find-ziya"
                >
                  <div className="section-container text-start">
                    <div className="content-title-heading">
                      <h1 className="text-title-heading">Find My Ziya</h1>
                    </div>
                    <div className="breadcrumbs">
                      <Link to="/">Home</Link>
                      <span className="delimiter" />
                      Find My Ziya
                    </div>
                  </div>
                </div>
                <Container>
                  <Carousel responsive={responsive}>
                    {ziyaLocation?.map((addressData) => (
                      <div className="testimonials-item">
                        {addressData.isActive ? (
                          <Card className="addreshCard">
                            <div className="iconDiv">
                              <img
                                className="iconAdress"
                                src={`${process.env.REACT_APP_API_URL_ZIYA}/${addressData.StoreLogo}`}
                                alt="icon"
                              />
                            </div>

                            <div>
                              <h4 className="addressTitle mt-2">
                                {addressData.Location}
                              </h4>
                              <p>{addressData.Address}</p>
                            </div>
                          </Card>
                        ) : null}
                      </div>
                    ))}
                  </Carousel>
                </Container>
                <section className="row section section-padding background-img bg-img-7 p-t-70 p-b-70 m-b-0 mt-4">
                  <div className="col-lg-6 col-12">
                    <MyMap locations={ziyaLocation} />
                  </div>
                  <div className="col-lg-6 col-12">
                    <div className="section-container small">
                      {/* Block Contact Form */}
                      <div className="block block-contact-form">
                        <div className="block-widget-wrap">
                          <div className="block-title">
                            <h2>FIND A NEARBY ZIYA STORE</h2>
                          </div>
                          <div className="block-content">
                            <form
                              action=""
                              method="post"
                              className="contact-form"
                              noValidate="novalidate"
                            >
                              <div className="contact-us-form">
                                <div className="row">
                                  <div className="col-sm-12 col-md-12">
                                    <label className="required">Country</label>
                                    <br />

                                    <Form.Select
                                      // className="form-control-wrap"
                                      className="store-loacte-select"
                                      aria-label="Default select example"
                                      value={CountryID}
                                      onChange={(e) => {
                                        setCountry(e.target.value);
                                        console.log(e.target.value);
                                      }}
                                    >
                                      <option>Select Country</option>
                                      {countries.map((c) => {
                                        return (
                                          c.isActive && (
                                            <option value={c._id}>
                                              {" "}
                                              {c.CountryName}{" "}
                                            </option>
                                          )
                                        );
                                      })}
                                    </Form.Select>
                                  </div>
                                  <div className="col-sm-12 col-md-12">
                                    <label className="required">City</label>
                                    <br />

                                    <Form.Select
                                      // className="form-control-wrap"
                                      className="store-loacte-select"
                                      aria-label="Default select example"
                                      name="CityID"
                                      value={CityID}
                                      onChange={(e) => {
                                        setCity(e.target.value);
                                        console.log(e.target.value);
                                      }}
                                    >
                                      <option>Select City</option>
                                      {cities.map((C) => {
                                        return (
                                          C.isActive &&
                                          CountryID == C.CountryID && (
                                            <option value={C._id}>
                                              {" "}
                                              {C.CityName}{" "}
                                            </option>
                                          )
                                        );
                                      })}
                                    </Form.Select>
                                  </div>
                                </div>
                                <div className="form-button">
                                  <input
                                    type="button"
                                    defaultValue="Locate"
                                    className="button"
                                    onClick={handleLocate}
                                  />
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              </div>

              <Footer />
              {showBackToTopButton && (
                <div className="back-top button-show" onClick={scrollToTop}>
                  <i className="arrow_carrot-up" />
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Find_My_Ziya;
