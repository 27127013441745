import React, { useState } from "react";
import Header from "../../components/Header";
import { Formik } from "formik";
import * as Yup from "yup";
import Footer from "../../components/Footer";
import { Link } from "react-router-dom";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

const ChangePassword = () => {
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    password: Yup.string()
      .required("Password is a required field")
      .min(8, "Password must be at least 8 characters"),
  });
  const [password, setPassword] = useState("");
  const [userId, setUserId] = useState(localStorage.getItem("RegisterUserID"));

  const handleChangePassword = (e) => {
    setPassword({ [e.target.name]: e.target.value });
  };

  return (
    <React.Fragment>
      <Header />
      <div id="site-main" className="site-main">
        <div id="main-content" className="main-content">
          <div id="primary" className="content-area">
            <div
              id="title"
              className="page-title page-title-dark title-collection"
            >
              <div className="section-container text-start">
                <div className="content-title-heading">
                  <h1 className="text-title-heading">Change Password</h1>
                </div>
                <div className="breadcrumbs">
                  <Link to="/">Home</Link>
                  <span className="delimiter" />
                  Change Password
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Container>
        <h1 className="myAcoountTitle">My Account</h1>
        <Row>
          <Col lg="3">
            <Link className="myAccountLinkDiv" to="/">
              <div className="myAccountLink">Overview</div>
            </Link>
            <Link to="/my-account-new">
              <div className="myAccountLink">Personal Information</div>
            </Link>
            <Link to="/whishlistnew">
              <div className="myAccountLink">Wishlist</div>
            </Link>
            <Link to="/ziya-contact">
              <div className="myAccountLink">Inquire About Us</div>
            </Link>
            <Link to="/change-password">
              <div className="myAccountLink">Change Password</div>
            </Link>
          </Col>
          <Col lg="9">
            <h1>Change Password</h1>
            <Card className="changPasswordCard">
              <Formik
                validationSchema={schema}
                initialValues={{ email: "", password: "" }}
                onSubmit={(values) => {
                  // Alert the input values of the form that we filled
                  alert(JSON.stringify(values));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => (
                  <div className="login">
                    <div className="form">
                      <form noValidate onSubmit={handleSubmit}>
                        <Row>
                          <Col className="changPasswordCol" lg="6">
                            <input
                              type="password"
                              name="password"
                              onChange={handleChangePassword}
                              // onBlur={handleBlur}
                              value={values.email}
                              placeholder="Enter old Password"
                              className="form-control inp_text"
                              id="email"
                              style={{ height: "50px" }}
                            />
                            <p className="error">
                              {errors.email && touched.email && errors.email}
                            </p>
                          </Col>
                          <Col className="changPasswordCol" lg="6">
                            <input
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              placeholder="Enter New password"
                              className="form-control"
                              style={{ height: "50px" }}
                            />
                            <p className="error">
                              {errors.password &&
                                touched.password &&
                                errors.password}
                            </p>
                          </Col>
                          <Col className="changPasswordCol" lg="6">
                            <input
                              type="password"
                              name="password"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              placeholder="Enter Confirm Password"
                              className="form-control"
                              style={{ height: "50px" }}
                            />
                            <p className="error">
                              {errors.password &&
                                touched.password &&
                                errors.password}
                            </p>
                          </Col>
                          <div className="intro-btn">
                            <Button
                              type="submit"
                              className="button button-outline animation-horizontal"
                            >
                              Submit Now
                            </Button>
                          </div>
                        </Row>
                      </form>
                    </div>
                  </div>
                )}
              </Formik>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </React.Fragment>
  );
};

export default ChangePassword;
