import React from "react";
import { Link } from "react-router-dom";

const StoreLocater = () => {
  return (
    <div>
      {/* <div className="sticky-donate">
        <Link to="/find-my-ziya">
          <div className="sticky-donate-text ">
            <span>STORE LOCATOR</span>
          </div>
        </Link>
      </div> */}
    </div>
  );
};

export default StoreLocater;
