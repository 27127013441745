import React, { useEffect, useState } from "react";
import { Puff } from "react-loader-spinner";
import micon from "../../assets/media/about/mangalsutra-icon.png";
import Banner from "../../assets/media/about/who1.jpg";
import Banner1 from "../../assets/media/about/who2.jpg";
import Banner2 from "../../assets/media/about/who3.jpg";
import Introimg from "../../assets/media/about/who4.jpg";
import Introimg1 from "../../assets/media/about/who5.jpg";
import Introimg2 from "../../assets/media/about/who6.jpg";
import Footer from "../../components/Footer";
import Header from "../../components/Header";

import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import StoreLocater from "../../components/StoreLocator/StoreLocater";

const Who_We_Are = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Show the button when the user scrolls down 200 pixels
        setShowBackToTopButton(true);
      } else {
        // Hide the button when the user scrolls back to the top
        setShowBackToTopButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <Helmet>
        <title>
          Explore Exclusive Mangalsutra Collection: Gold, Diamond, and Bridal
          Styles
        </title>
        <meta
          name="description"
          content="Explore our elegant mangalsutra collection with special gold, diamond, and bridal designs. Elevate your style with our exquisite pieces. Discover now! Visit Now"
        />
        <link rel="canonical" href="https://ziyamangalsutra.com/who-we-are" />

        <meta name="keywords" content="Gold mangalsutra, diamond mangalsutra" />
        {/* <!----======= Seo Meta  =======----> */}
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="author" content="" />
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Explore our elegant mangalsutra collection with special gold, diamond, and bridal designs. Elevate your style with our exquisite pieces. Discover now! Visit Now"
        />
        <meta
          name="og:title"
          content="Explore Exclusive Mangalsutra Collection: Gold, Diamond, and Bridal Styles"
        />
        <meta
          name="og:description"
          content="Explore our elegant mangalsutra collection with special gold, diamond, and bridal designs. Elevate your style with our exquisite pieces. Discover now! Visit Now"
        />
        <meta
          name="og:image"
          content="https://ziyamangalsutra.com/static/media/who1.6a1b88ea58c2da11a2c5.jpg"
        />
        <meta name="og:url" content="https://ziyamangalsutra.com/who-we-are" />
        <meta name="og:site_name" content="Ziya Mangalsutra" />
        <meta name="og:locale" content="en_IN" />
        <meta name="og:video" content="" />
        <meta name="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Explore Exclusive Mangalsutra Collection: Gold, Diamond, and Bridal Styles"
        />
        <meta
          name="twitter:description"
          content="Explore our elegant mangalsutra collection with special gold, diamond, and bridal designs. Elevate your style with our exquisite pieces. Discover now! Visit Now"
        />
        <meta
          name="twitter:image"
          content="https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png"
        />
        <meta name="twitter:site" content="@ziyamangalsutra" />
        <meta name="twitter:creator" content="@ziyamangalsutra" />
        {/* <!----======= Schema Markup Script =======----> */}
        <script
          type="application/ld+json"
          class="yoast-schema-graph yoast-schema-graph--main"
        >
          {`
        {
        "@context":"https://schema.org",
        "@graph":[ {
        "@type":"WebSite",
        "@id":"https://ziyamangalsutra.com/who-we-are/#webpage",
        "url":"https://ziyamangalsutra.com/who-we-are",
        "name":"Ziya Mangalsutra",
        "description":"Explore our elegant mangalsutra collection with special gold, diamond, and bridal designs. Elevate your style with our exquisite pieces. Discover now! Visit Now"
		}
        ,
        {
        "@type":"WebPage",
        "@id":"https://ziyamangalsutra.com/who-we-are/#webpage",
        "url":"https://ziyamangalsutra.com/who-we-are",
        "inLanguage":"en-US",
        "name":"Ziya Mangalsutra",
        "isPartOf": {
        "@id": "https://ziyamangalsutra.com/who-we-are"
        }
        ,
        "datePublished":"2017-09-20T09:19:08+00:00",
        "dateModified":"2020-11-25T06:40:11+00:00",
        "description":"Explore our elegant mangalsutra collection with special gold, diamond, and bridal designs. Elevate your style with our exquisite pieces. Discover now! Visit Now"
        }
        ]
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "http://www.schema.org",
        "@type": "product",
        "name": "Ziya Mangalsutra ",
        "image": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "sku": "IF",
        "mpn": "IF",
        "description": "Explore our elegant mangalsutra collection with special gold, diamond, and bridal designs. Elevate your style with our exquisite pieces. Discover now! Visit Now",
        "aggregateRating": {"@type": "aggregateRating", "ratingValue": "5", "reviewCount": "1", "bestRating":"5 " }
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "https://schema.org/",
        "@type": "Person",
        "name": "Mr Chetan Thadeshwar",
        "url": "https://ziyamangalsutra.com/",
        "image": "https://ziyamangalsutra.com/static/media/who1.6a1b88ea58c2da11a2c5.jpg",
        "jobTitle": "Proprietor",
        "worksFor": {
        "@type": "Organization",
        "name": "Ziya Mangalsutra"
        }
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "http://www.schema.org",
        "@type": "Organization",
        "name": "Ziya Mangalsutra",
        "url": "https://ziyamangalsutra.com/who-we-are"
        "logo": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "image": "https://ziyamangalsutra.com/static/media/who1.6a1b88ea58c2da11a2c5.jpg",
        "description": "Explore our elegant mangalsutra collection with special gold, diamond, and bridal designs. Elevate your style with our exquisite pieces. Discover now! Visit Now",
        "address": {
        "@type": "PostalAddress",
        "streetAddress": "Cotton Exchange Building, B-1, Jewel World, Kalbadevi Rd, opp. Surati hotel, Mumbai, Maharashtra - 400002",
        "addressLocality": "Surati hotel",
        "addressRegion": "Mumbai",
        "addressCountry": "India"
        },
        "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+022 43111222"
        }
        }
        `}
        </script>
      </Helmet>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#00BFFF"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Header />
          <div id="site-main" className="site-main">
            <div id="main-content" className="main-content">
              <div id="primary" className="content-area">
                <div
                  id="title"
                  className="page-title page-title-dark title-who-we-are"
                >
                  <div className="section-container text-start">
                    <div className="content-title-heading">
                      <h1 className="text-title-heading">Who We Are</h1>
                    </div>
                    <div className="breadcrumbs">
                      <Link to="/">Home</Link>
                      <span className="delimiter" />
                      Who We Are
                    </div>
                  </div>
                </div>
                <div id="content" className="site-content" role="main">
                  <div className="page-about-us">
                    <section className="section section-padding m-b-70">
                      <div className="section-container">
                        {/* Block Intro (Layout 5) */}
                        <div className="block block-intro layout-5 text-center">
                          <div className="block-widget-wrap">
                            <div className="intro-wrap">
                              <div className="intro-icon animation-horizontal">
                                <img src={micon} alt="" />
                              </div>
                              <h2 className="intro-title">
                                "Elevating Traditions, Celebrating Love"
                              </h2>
                              <div className="intro-text">
                                Introducing ZIYA, the pioneering and exclusive
                                branded Mangalsutra collection from Shringar –
                                House of Mangalsutra, a renowned establishment
                                with a legacy dating back to 1963. Our
                                collection is meticulously crafted to offer
                                top-tier quality and features stunning designs
                                that resonate seamlessly with contemporary
                                preferences, ensuring that you adorn nothing
                                short of excellence.
                              </div>
                              <div className="intro-text">
                                Shringar – House of Mangalsutra stands as a
                                trailblazer in the Mangalsutra industry, having
                                produced millions of exquisitely handcrafted
                                Mangalsutras, each distinguished by its unique
                                design, style, and cultural heritage. Tailored
                                to cater to the diverse needs of every woman,
                                Shringar has emerged as the preferred choice of
                                retailers not only across India but also in more
                                than 14 countries, owing to its extensive
                                Mangalsutra portfolio.
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="section section-padding m-t-20 m-b-70">
                      <div className="section-container">
                        {/* Block Banners */}
                        <div className="block block-banners banners-effect">
                          <div className="block-widget-wrap">
                            <div className="row">
                              <div className="col-md-4 sm-m-b-20">
                                <div className="block-widget-banner">
                                  <div className="bg-banner">
                                    <div className="banner-wrapper banners">
                                      <div className="banner-image hover-opacity">
                                        <Link to="#">
                                          <img
                                            src={Banner}
                                            alt="Banner Image"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4 sm-m-b-20">
                                <div className="block-widget-banner">
                                  <div className="bg-banner">
                                    <div className="banner-wrapper banners">
                                      <div className="banner-image hover-opacity">
                                        <Link to="#">
                                          <img
                                            src={Banner1}
                                            alt="Banner Image"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <div className="block-widget-banner">
                                  <div className="bg-banner">
                                    <div className="banner-wrapper banners">
                                      <div className="banner-image hover-opacity">
                                        <Link to="#">
                                          <img
                                            src={Banner2}
                                            alt="Banner Image"
                                          />
                                        </Link>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                    <section className="section section-padding background-img bg-img-2 p-t-70 p-b-70 m-b-70">
                      <div className="section-container">
                        {/* Block Intro (Layout 6) */}
                        <div className="block block-intro layout-6">
                          <div className="block-widget-wrap">
                            <div className="row">
                              <div className="section-column left">
                                <div className="intro-wrap">
                                  <h2 className="intro-title">
                                    Unparalleled craftsmanship, <br />
                                    impeccable designs
                                  </h2>
                                  <div className="intro-item m-b-0">
                                    Based in Mumbai, India, Shringar – House of
                                    Mangalsutra has become synonymous with
                                    unparalleled craftsmanship, impeccable
                                    designs, an unwavering commitment to product
                                    quality, and exceptional after-sales
                                    services. Our firm commitment to ethical
                                    sourcing, continuous design innovation, and
                                    the upholding of timeless values sets us
                                    apart.{" "}
                                  </div>
                                </div>
                              </div>
                              <div className="section-column right">
                                <div className="row">
                                  <div className="col-md-6">
                                    <div className="intro-image left animation-horizontal hover-opacity">
                                      <img
                                        src={Introimg}
                                        alt="intro"
                                        className="whoWeAreImg"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-md-6">
                                    <div className="intro-image right animation-horizontal hover-opacity">
                                      <img
                                        width={330}
                                        height={419}
                                        src={Introimg1}
                                        alt="intro"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>

                    <section className="section section-padding background-13 p-t-70 p-b-70 m-b-70">
                      <div className="section-container">
                        {/* Block Intro (Layout 7) */}
                        <div className="block block-intro layout-7">
                          <div className="block-widget-wrap">
                            <div className="row">
                              <div className="section-column left animation-horizontal hover-opacity">
                                <img
                                  width={600}
                                  height={440}
                                  src={Introimg2}
                                  alt="intro"
                                />
                              </div>
                              <div className="section-column right">
                                <div className="intro-wrap">
                                  <h2 className="intro-title">
                                    Opulent collection, Exclusively crafted
                                  </h2>
                                  <div className="intro-item m-b-0">
                                    With a keen focus on enhancing your jewelry
                                    collection, Shringar takes immense pride in
                                    presenting ZIYA, its most opulent
                                    collection, exclusively crafted to elevate
                                    your style. Experience the epitome of
                                    Mangalsutra artistry with ZIYA, where
                                    tradition meets innovation, and quality is
                                    paramount. Explore our collection today for
                                    a truly unmatched jewelry experience.{" "}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </section>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Footer />

          {showBackToTopButton && (
            <div className="back-top button-show" onClick={scrollToTop}>
              <i className="arrow_carrot-up" />
            </div>
          )}
        </div>
      )}
      <StoreLocater />
    </div>
  );
};

export default Who_We_Are;
