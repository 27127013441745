import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { Helmet } from "react-helmet";
import { Puff } from "react-loader-spinner";
import { Link } from "react-router-dom";
import Footer from "../../components/Footer";
import Header from "../../components/Header";
import StoreLocater from "../../components/StoreLocator/StoreLocater";
import { listCategory } from "../../functions/ProductDetails/Category";

const The_Collection = () => {
  const [activeTab, setActiveTab] = useState("bridal");
  const [showAllBridal, setShowAllBridal] = useState(false);
  const [showBackToTopButton, setShowBackToTopButton] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const [colectionData, setColectionData] = useState([]);

  useEffect(() => {
    loadCatgeories();
  }, []);

  const toggleShowAllBridal = () => {
    setShowAllBridal(!showAllBridal);
  };
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const loadCatgeories = () => {
    listCategory().then((res) => {
      setColectionData(res.data);
    });
  };

  useEffect(() => {
    // Simulate a delay of 2 seconds (adjust as needed)
    const delay = 1000;
    setTimeout(() => {
      setIsLoading(false);
    }, delay);
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 200) {
        // Show the button when the user scrolls down 200 pixels
        setShowBackToTopButton(true);
      } else {
        // Hide the button when the user scrolls back to the top
        setShowBackToTopButton(false);
      }
    };

    // Attach the scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div>
      <Helmet>
        <title>
          Buy Gold or Diamond Mangalsutra | Latest Mangalsutra Designs
        </title>
        <meta
          name="description"
          content="Discover our newest collection of mangalsutra designs. Shop for elegant gold or diamond mangalsutra pieces at Ziya Mangalsutra. Shop Gold at affordable price!"
        />
        <link
          rel="canonical"
          href="https://ziyamangalsutra.com/the-collection"
        />

        <meta
          name="keywords"
          content="long mangalsutra designs gold, short mangalsutra designs,diamond mangalsutra, Gold mangalsutra"
        />
        {/* <!----======= Seo Meta  =======----> */}
        <meta http-equiv="X-UA-Compatible" content="IE=edge" />
        <meta name="author" content="" />
        <meta name="robots" content="index,follow" />
        <meta
          name="description"
          content="Discover our newest collection of mangalsutra designs. Shop for elegant gold or diamond mangalsutra pieces at Ziya Mangalsutra. Shop Gold at affordable price!"
        />
        <meta
          name="og:title"
          content="Buy Gold or Diamond Mangalsutra | Latest Mangalsutra Designs"
        />
        <meta
          name="og:description"
          content="Discover our newest collection of mangalsutra designs. Shop for elegant gold or diamond mangalsutra pieces at Ziya Mangalsutra. Shop Gold at affordable price!"
        />
        <meta
          name="og:image"
          content="https://ziyamangalsutra.com/static/media/exp1.a30a1a0cdf77cffc0620.jpg"
        />
        <meta
          name="og:url"
          content="https://ziyamangalsutra.com/the-collection"
        />
        <meta name="og:site_name" content="Ziya Mangalsutra" />
        <meta name="og:locale" content="en_IN" />
        <meta name="og:video" content="" />
        <meta name="og:type" content="website" />
        <meta
          name="twitter:title"
          content="Buy Gold or Diamond Mangalsutra | Latest Mangalsutra Designs"
        />
        <meta
          name="twitter:description"
          content="Discover our newest collection of mangalsutra designs. Shop for elegant gold or diamond mangalsutra pieces at Ziya Mangalsutra. Shop Gold at affordable price!"
        />
        <meta
          name="twitter:image"
          content="https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png"
        />
        <meta name="twitter:site" content="@ziyamangalsutra" />
        <meta name="twitter:creator" content="@ziyamangalsutra" />

        {/* <!----======= Schema Markup Script =======----> */}
        <script
          type="application/ld+json"
          class="yoast-schema-graph yoast-schema-graph--main"
        >
          {`
        {
        "@context":"https://schema.org",
        "@graph":[ {
        "@type":"WebSite",
        "@id":"https://ziyamangalsutra.com/the-collection/#webpage",
        "url":"https://ziyamangalsutra.com/the-collection",
        "name":"Ziya Mangalsutra",
        "description":"Discover our newest collection of mangalsutra designs. Shop for elegant gold or diamond mangalsutra pieces at Ziya Mangalsutra. Shop Gold at affordable price!"
		}
        ,
        {
        "@type":"WebPage",
        "@id":"https://ziyamangalsutra.com/the-collection/#webpage",
        "url":"https://ziyamangalsutra.com/the-collection",
        "inLanguage":"en-US",
        "name":"Ziya Mangalsutra",
        "isPartOf": {
        "@id": "https://ziyamangalsutra.com/the-collection"
        }
        ,
        "datePublished":"2017-09-20T09:19:08+00:00",
        "dateModified":"2020-11-25T06:40:11+00:00",
        "description":"Discover our newest collection of mangalsutra designs. Shop for elegant gold or diamond mangalsutra pieces at Ziya Mangalsutra. Shop Gold at affordable price!"
        }
        ]
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "http://www.schema.org",
        "@type": "product",
        "name": "Ziya Mangalsutra ",
        "image": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "sku": "IF",
        "mpn": "IF",
        "description": "Discover our newest collection of mangalsutra designs. Shop for elegant gold or diamond mangalsutra pieces at Ziya Mangalsutra. Shop Gold at affordable price!",
        "aggregateRating": {"@type": "aggregateRating", "ratingValue": "5", "reviewCount": "1", "bestRating":"5 " }
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "https://schema.org/",
        "@type": "Person",
        "name": "Mr Chetan Thadeshwar",
        "url": "https://ziyamangalsutra.com/the-collection",
        "image": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "jobTitle": "Proprietor",
        "worksFor": {
        "@type": "Organization",
        "name": "Ziya Mangalsutra"
        }
        }
        `}
        </script>
        <script type="application/ld+json">
          {`
        {
        "@context": "http://www.schema.org",
        "@type": "Organization",
        "name": "Ziya Mangalsutra",
        "url": "https://ziyamangalsutra.com/the-collection"
        "logo": "https://ziyamangalsutra.com/static/media/LogoNew.6f3b49725e00df4eef21.png",
        "image": "https://ziyamangalsutra.com/static/media/exp1.a30a1a0cdf77cffc0620.jpg",
        "description": "Discover our newest collection of mangalsutra designs. Shop for elegant gold or diamond mangalsutra pieces at Ziya Mangalsutra. Shop Gold at affordable price!",
        "address": {
        "@type": "PostalAddress",
        "streetAddress": "Cotton Exchange Building, B-1, Jewel World, Kalbadevi Rd, opp. Surati hotel, Mumbai, Maharashtra - 400002",
        "addressLocality": "Surati hotel",
        "addressRegion": "Mumbai",
        "addressCountry": "India"
        },
        "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+022 43111222"
        }
        }
        `}
        </script>
      </Helmet>
      {isLoading ? (
        // Loader component while loading
        <div className="loader-container">
          <Puff
            color="#00BFFF"
            height={50}
            width={50}
            timeout={0} // 0 means no timeout, loader will be displayed until setIsLoading(false) is called
          />
        </div>
      ) : (
        <div>
          <Header />

          <div id="site-main" className="site-main">
            <div id="main-content" className="main-content">
              <div id="primary" className="content-area">
                <div
                  id="title"
                  className="page-title page-title-dark title-collection"
                >
                  <div className="section-container text-start">
                    <div className="content-title-heading">
                      <h1 className="text-title-heading">The Collection</h1>
                    </div>
                    <div className="breadcrumbs">
                      <Link to="/">Home</Link>
                      <span className="delimiter" />
                      The Collection
                    </div>
                  </div>
                </div>
                <div id="content" className="site-content" role="main">
                  <section className="section section-padding   p-t-70 p-b-50 m-b-70">
                    <div className="section-container small">
                      <div className="block block-intro layout-4">
                        <div className="block-widget-wrap">
                          <Row className="colectionRow">
                            <h1 className="colectionTitle">The Collection</h1>
                            {colectionData?.map(
                              (colection) =>
                                colection.isActive &&
                                !colection.IsTopProducts &&
                                colection.IsPublic && (
                                  <Col md="6" className="col-5-new">
                                    <Link
                                      to={`/product-details/${colection._id}`}
                                    >
                                      <div className="containerOne">
                                        <div class="section-column right">
                                          <img
                                            class="animation-horizontal"
                                            src={`${process.env.REACT_APP_API_URL_ZIYA}/${colection.CategoryImage}`}
                                            alt="intro"
                                          />
                                          <div className="text-block">
                                            <h3
                                              className="iconTitle"
                                              style={{ color: "#fff" }}
                                            >
                                              {colection.Category}
                                            </h3>
                                          </div>
                                        </div>
                                      </div>
                                    </Link>
                                  </Col>
                                )
                            )}
                          </Row>
                        </div>
                      </div>
                    </div>
                  </section>
                </div>
              </div>
            </div>
          </div>

          <Footer />
          {showBackToTopButton && (
            <div className="back-top button-show" onClick={scrollToTop}>
              <i className="arrow_carrot-up" />
            </div>
          )}
        </div>
      )}

      <StoreLocater />
    </div>
  );
};

export default The_Collection;
