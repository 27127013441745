import axios from "axios";

export const getTryJewel = async (_id) => {
    return await axios.get(
      `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/get-try-jewel/${_id}`
    );
  };

  export const getTryJewelByProduct = async (_id) => {
    return await axios.get(
      `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/get-try-jewel-by-productId/${_id}`
    );
  };