import React from "react";
import { PiUserFocus } from "react-icons/pi";
import { Card } from "reactstrap";

const TryOnCardOverLap = () => {
  return (
    <React.Fragment>
      <Card className="overLapCard">
        <div className="overLapDiv">
          <div className="foucsIconDiv">
            <PiUserFocus />
          </div>
          <h6 className="findFaceTitle">FIND A FACE</h6>
          <p className="descriptionOverLap">
            To achieve the best result keep 10-inch distance, and ensure clear
            visibility!
          </p>
        </div>
      </Card>
    </React.Fragment>
  );
};

export default TryOnCardOverLap;
