import axios from "axios";

export const listCountry = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/location/country`
  );
};

export const listCity = async () => {
    return await axios.get(
      `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/location/city`
    );
  };