import axios from "axios";

export const listZiyaLocation = async () => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/locations/ziya`
  );
};

export const findZiyaLocation = async (country, city) => {
  return await axios.get(
    `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/find-locations/ziya/${country}/${city}`
  );
};

// export const getPartnerLoginData = async (username, password) => {
//   return await axios.get(
//     `${process.env.REACT_APP_API_URL_ZIYA}/api/auth/find-partner-user-details/${username}/${password}`
//   );
// };
