import { useEffect, useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import * as Yup from "yup";
// import popup from "../../assets/media/brand/4.jpg";
import popup from "../../assets/media/banner/1.jpg";

import SignUpModal from "./SignUp";
import { listZiyaLocation } from "../../functions/ZiyaLocation";
// import { getPartnerLoginData } from "../../functions/ZiyaLocation";

function PartnerLoginModal({ Color }) {
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is  required ")
      .email("Invalid email format"),
    password: Yup.string()
      .required("Password is  required ")
      .min(6, "Password must be at least 6 characters"),
  });

  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [username, setusername] = useState("");
  const [password, setpassword] = useState("");

  const [errUN, setErrUN] = useState(false);
  const [errPass, setErrPass] = useState(false);

  const validateDetails = (username, password) => {
    const errors = {};

    if (!username) {
      errors.username = "User Name is required!";
      setErrUN(true);
    }

    if (username) {
      setErrUN(false);
    }

    if (!password) {
      errors.password = "Password is required!";
      setErrPass(true);
    }

    if (password) {
      setErrPass(false);
    }

    return errors;
  };

  const validClassUN =
    errUN && isSubmit
      ? "form-control is-invalid inp_text"
      : "form-control inp_text";

  const validClassPass =
    errPass && isSubmit ? "form-control is-invalid" : "form-control";

  // const handleSubmit = (e) => {
  //   e.preventDefault();

  //   // set_Id(_id);
  //   let erros = validateDetails(username, password);
  //   setFormErrors(erros);
  //   setIsSubmit(true);

  //   if (Object.keys(erros).length == 0) {
  //     getPartnerLoginData(username, password)
  //       .then((res) => {
  //         console.log("log user res", res.data);
  //         if (res.data.isOk) {
  //           console.log(res);
  //           const userLog = res.data;
  //           console.log("dd", userLog.data);
  //           localStorage.setItem("PartnerID", userLog.data);
  //           setusername("");
  //           setpassword("");
  //           setShow(!show);
  //         } else {
  //           const user = res.data;
  //           if (user.field === 1) {
  //             setErrPass(true);
  //             setFormErrors({ password: user.message });
  //           } else if (user.field === 2) {
  //             setErrUN(true);
  //             setFormErrors({ username: user.message });
  //           }
  //         }
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //       });
  //   }
  // };

  const [show, setShow] = useState(false);
  const [locations, setLocations] = useState([]);

  const tog_list = () => {
    setShow(!show);
  };

  useEffect(() => {
    loadLocations();
  }, []);

  const loadLocations = () => {
    listZiyaLocation().then((res) => {
      console.log("RES IN LOCATIONS", res.data);
      setLocations(res.data);
    });
  };

  const handleChangeun = (e) => {
    setusername(e.target.value);
  };

  const handleChangepass = (e) => {
    setpassword(e.target.value);
  };
  const closeModal = () => {
    setIsOpen(false);
    localStorage.setItem("subscribed", "true");
  };
  const [isOpen, setIsOpen] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <Link onClick={() => tog_list()} className="menu-link">
        {/* <Link onClick={handleShow} className="menu-link"> */}
        <span className="menu-item-text " style={{ color: Color }}>
          Partner Login
        </span>
      </Link>

      <Modal
        centered
        show={show}
        // onHide={handleClose}
        toggle={() => {
          tog_list();
        }}
      >
        <div className="newsletter-popup">
          <a
            to="#"
            className="newsletter-close"
            // onClick={handleClose}
            onClick={() => {
              tog_list();
            }}
          />
          <div className="newsletter-container">
            <div className="newsletter-img">
              <img src={popup} alt />
            </div>
            <div className="newsletter-form">
              {/* <Formik
                validationSchema={schema}
                initialValues={{ email: "", password: "" }}
                onSubmit={(values) => {
                  // Alert the input values of the form that we filled
                  alert(JSON.stringify(values));
                }}
              >
                {({
                  values,
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  handleSubmit,
                }) => ( */}
              <div className="login">
                <div className="form">
                  <form>
                    <div style={{ marginBottom: "30px" }}>
                      <h5>
                        {" "}
                        <b>Partner Login</b>
                      </h5>
                    </div>
                    <div className=" mb-3">
                      <label
                        className="lableEmail"
                        // style={{ paddingTop: "5px" }}
                      >
                        user name
                      </label>
                      <input
                        type="text"
                        style={{ borderColor: "black" }}
                        className={validClassUN}
                        id="username"
                        placeholder="Enter Your user name"
                        required
                        name="username"
                        value={username}
                        onChange={handleChangeun}
                      />
                      {/* If validation is not passed show errors */}
                      {isSubmit && (
                        <p className="text-danger">{formErrors.username}</p>
                      )}

                      {/* <label
                        className="lableEmail"
                        
                      >
                        UserName
                      </label> */}
                    </div>
                    <div className=" mb-3">
                      <label
                        className="lableEmail"
                        // style={{ paddingTop: "5px" }}
                      >
                        Password
                      </label>
                      <input
                        type="text"
                        style={{ borderColor: "black" }}
                        className={validClassPass}
                        id="password"
                        placeholder="Enter password"
                        required
                        name="password"
                        value={password}
                        onChange={handleChangepass}
                      />
                      {/* If validation is not passed show errors */}
                      {isSubmit && (
                        <p className="text-danger">{formErrors.password}</p>
                      )}

                      {/* <label className="lablepass">Password</label> */}
                    </div>
                    {/* Click on submit button to submit the form */}
                    {/* <button
                      type="submit"
                      className="button button-primary animation-horizontal"
                      onClick={handleSubmit}
                    >
                      Submit
                    </button> */}
                  </form>
                </div>
              </div>
              {/* )}
              </Formik> */}
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}

export default PartnerLoginModal;
