import { useState } from "react";

import Modal from "react-bootstrap/Modal";
import { Link } from "react-router-dom";
import popup from "../../assets/media/banner/1.jpg";
// import LoginModal from "./loginModal";

import { Col, Input, Label, Row } from "reactstrap";
import { createProspect } from "../../functions/Prospect/Prsopect";

const initialStateProspect = {
  ContactPersonName: "",
  City: "",
  EmailID: "",
  ContactNo: "",
  IsActive: true,
};

const CSignup = ({
  modalListSignup,
  setModalListSignUp,
  handleclick,
  TogListRegister,
}) => {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const [prospect, setProspect] = useState(initialStateProspect);
  const { ContactPersonName, City, EmailID, ContactNo, IsActive } = prospect;

  const handleSubmitProspectSignup = (e) => {
    e.preventDefault();

    // set_Id(_id);
    let erros = validateProspect(prospect);
    setFormErrors(erros);
    setIsSubmit(true);

    if (Object.keys(erros).length == 0) {
      setProspect({ ...prospect, IsActive: true });
      console.log("ss", IsActive);
      createProspect(prospect)
        .then((res) => {
          console.log("create prospect res", res.data);
          if (res.data.isOk) {
            console.log("res in created", res.data);
            const resp = res.data;
            localStorage.setItem("RegisterUserID", resp.data._id);
            setProspect(initialStateProspect);
            setModalListSignUp(!modalListSignup);
          } else {
            if (res.data.field === 1) {
              setFormErrors({
                ContactNo: "Account with this Contact Number already exists!",
              });
              setErrCo(true);
            } else if (res.data.field === 2) {
              setFormErrors({ EmailID: res.data.message });
              setErrPE(true);
            }
            // toast.error(res.message);
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }

    // loadDetailForm();
  };

  // const tog_list = () => {
  //   setModalListSignUp(!modalListSignup);
  //   setIsSubmit(false);
  // };

  const handleChangeProspect = (e) => {
    setProspect({ ...prospect, [e.target.name]: e.target.value });
  };

  const [errCPN, setErrCPN] = useState(false);
  const [errPC, setErrPC] = useState(false);
  const [errCo, setErrCo] = useState(false);
  const [errPE, setErrPE] = useState(false);

  const [errActive, setErrActive] = useState(false);

  const validateProspect = (prospect) => {
    const errors = {};
    const company = /^(?!\s)(?!.*\s$)(?=.*[a-zA-Z0-9])[a-zA-Z0-9]{2,}$/;
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    const phone = /^\d{10}$/;

    if (!prospect.ContactPersonName) {
      errors.ContactPersonName = "Contact Person Name is required!";
      setErrCPN(true);
    }
    if (prospect.ContactPersonName) {
      setErrCPN(false);
    }

    if (!prospect.City) {
      errors.City = "City is required!";
      setErrPC(true);
    }
    if (prospect.City) {
      setErrPC(false);
    }

    if (!prospect.ContactNo) {
      errors.ContactNo = "Contact No is required!";
      setErrCo(true);
    } else if (!phone.test(prospect.ContactNo)) {
      errors.ContactNo = "This is not a valid Phone Number";
      setErrCo(true);
    }
    if (prospect.ContactNo) {
      setErrCo(false);
    }
    if (!prospect.EmailID) {
      errors.EmailID = "Email is required!";
      setErrPE(true);
    } else if (!regex.test(prospect.EmailID)) {
      errors.EmailID = "This is not a valid email format!";
      setErrPE(true);
    }
    if (prospect.EmailID) {
      setErrPE(false);
    }

    //    DATE OF EXPIRY

    return errors;
  };

  const validClassProspectPersonName =
    errCPN && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassProspectContactNo =
    errCo && isSubmit ? "form-control is-invalid" : "form-control";
  const validClassProspectEmailID =
    errPE && isSubmit ? "form-control is-invalid" : "form-control";

  const validClassProspectCity =
    errPC && isSubmit ? "form-control is-invalid" : "form-control";

  return (
    <>
      {/* <Link onClick={handleShowSignup} className="menu-link">
        <span className="menu-item-text">SIGNUP HERE</span>
      </Link> */}

      <Modal
        size="lg"
        centered
        show={modalListSignup}
        // onHide={handlCloseSignup}
        // toggle={() => {
        //   tog_list();
        // }}
        onClick={TogListRegister}
      >
        <div className="newsletter-popup">
          <a
            to="#"
            className="newsletter-close"
            // onClick={() => {
            //   tog_list();
            // }}
            onClick={TogListRegister}
          />
          <div className="newsletter-container">
            <div
              className="newsletter-img"
              style={{ width: "500px", height: "auto" }}
            >
              <img src={popup} width="375px" height="500px" alt />
            </div>
            <div
              className="newsletter-form"
              style={{ width: "500px", height: "375px" }}
            >
              <div className="login">
                <div className="form">
                  {/* Passing handleSubmit parameter tohtml form onSubmit property */}
                  <form>
                    <div style={{ marginBottom: "40px" }}>
                      <h5> SIGN UP</h5>
                    </div>
                    {/* Our input html with passing formik parameters like handleChange, values, handleBlur to input properties */}
                    <Row>
                      <Col lg={6}>
                        {/* Contact Person Name  */}
                        <div className="mb-3">
                          <Label className="form-label">
                            Contact Person Name
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            type="text"
                            className={validClassProspectPersonName}
                            id="contactpersonnamevalidation"
                            placeholder="Enter Name"
                            required
                            name="ContactPersonName"
                            value={ContactPersonName}
                            onChange={handleChangeProspect}
                          />

                          {isSubmit && (
                            <p className="text-danger">
                              {formErrors.ContactPersonName}
                            </p>
                          )}
                        </div>
                      </Col>
                      <Col lg={6}>
                        {/* City */}
                        <div className="mb-3">
                          <Label className="form-label">
                            City
                            {/* <span className="text-danger">*</span> */}
                          </Label>

                          <Input
                            type="text"
                            className={validClassProspectCity}
                            id="companynamevalidation"
                            placeholder="Enter your City"
                            required
                            name="City"
                            value={City}
                            onChange={handleChangeProspect}
                          />

                          {isSubmit && (
                            <p className="text-danger">{formErrors.City}</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        {/* ContactNo */}
                        <div className="mb-3">
                          <Label className="form-label">
                            Contact Number
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            type="number"
                            className={validClassProspectContactNo}
                            id="companynamevalidation"
                            placeholder="Enter your Contact Number"
                            required
                            name="ContactNo"
                            value={ContactNo}
                            onChange={handleChangeProspect}
                          />

                          {isSubmit && (
                            <p className="text-danger">
                              {formErrors.ContactNo}
                            </p>
                          )}
                        </div>
                      </Col>

                      <Col lg={6}>
                        {/* EmailID */}
                        <div className="mb-3">
                          <Label className="form-label">
                            Email ID
                            {/* <span className="text-danger">*</span> */}
                          </Label>
                          <Input
                            type="email"
                            className={validClassProspectEmailID}
                            id="companynamevalidation"
                            placeholder="Enter your Email ID"
                            required
                            name="EmailID"
                            value={EmailID}
                            onChange={handleChangeProspect}
                          />

                          {isSubmit && (
                            <p className="text-danger">{formErrors.EmailID}</p>
                          )}
                        </div>
                      </Col>
                    </Row>
                    {/* <div className="lableEmail">
                      {" "}
                      <input type="checkBox" /> <span>Rember Me</span>
                    </div> */}

                    <p>
                      Have an account ?
                      <Link to="" onClick={() => handleclick("loginPage")}>
                        Sign In
                      </Link>
                      {/* <span>
                        <Link to="#">
                          <LoginModal />
                        </Link>
                      </span>{" "} */}
                    </p>
                    {/* Click on submit button to submit the form */}
                    <button
                      type="submit"
                      className="button button-primary animation-horizontal"
                      onClick={handleSubmitProspectSignup}
                    >
                      Submit
                    </button>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default CSignup;
