import { FaceMesh } from "@mediapipe/face_mesh";
import React, { useRef, useEffect, useState } from "react";
import * as Facemesh from "@mediapipe/face_mesh";
import * as cam from "@mediapipe/camera_utils";
import Webcam from "react-webcam";

import { Col, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { getTryJewelByProduct } from "../../functions/TryOn/TryJewel";
import { log } from "@tensorflow/tfjs-core/dist/log";
import TryOnCardOverLap from "./TryOnCardOverLap";

var camera = null;

const TryonNew2 = ({ isTry, setIsTry, tryProductId }) => {
  let prevX = 0;
  let prevY = 0;
  let prevWidth = 0;
  let prevHeight = 0;

  const [product, setProduct] = useState(false);
  const [faceDetected, setFaceDetected] = useState(false);

  const [landmarks, setLandmarks] = useState(null);
  // const [videoWidth, setvideoWidth] = useState(null);
  const [videoHeight, setvideoHeight] = useState(null);
  const [height, setHeight] = useState(null);
  const [xx, setxx] = useState(0.0);
  const [yy, setyy] = useState(1.0);
  const [ss, setss] = useState(1.0);
  const [selectedImage, setSelectedImage] = useState();

  const xxRef = useRef(xx);
  const yyRef = useRef(yy);
  const ssRef = useRef(ss);
  const selectedImageRef = useRef(selectedImage);

  useEffect(() => {
    xxRef.current = xx;
    yyRef.current = yy;
    ssRef.current = ss;
  }, [xx, yy, ss]);

  useEffect(() => {
    selectedImageRef.current = selectedImage;
  }, [selectedImage]);

  useEffect(() => {
    loadTryJewel();
  }, [tryProductId]);

  const loadTryJewel = () => {
    getTryJewelByProduct(tryProductId).then((res) => {
      setProduct(res.data);
      setss(res.data.scaleFactor);
      setxx(res.data.x);
      setyy(res.data.y);
      setSelectedImage(
        `${process.env.REACT_APP_API_URL_ZIYA}/${res.data.productImage}`
      );
    });
  };

  const handleChangeX = (e) => {
    prevX = null;
    // setValues({ ...values, x: e.target.value });
    setxx(e.target.value);
  };

  const handleChangeY = (e) => {
    prevX = null;
    // setValues({ ...values, y: e.target.value });
    setyy(e.target.value);
  };

  const handleChangeSF = (e) => {
    prevX = null;
    setss(e.target.value);
  };

  const checkDifference = (prevX, prevY, x, y, percentage) => {
    if (prevX === null) {
      return true;
    }
    if (
      prevX < x * (1 + percentage / 100) &&
      prevX > x * (1 - percentage / 100) &&
      prevY < y * (1 + percentage / 100) &&
      prevY > y * (1 - percentage / 100)
    ) {
      return false;
    }
    return true;
  };

  const webcamRef = useRef(null);
  const canvasRef = useRef(null);
  const connect = window.drawConnectors;

  function onResults(results) {
    // const video = webcamRef.current.video;
    const videoWidth = webcamRef.current.video.videoWidth;
    const videoHeight = webcamRef.current.video.videoHeight;
    setvideoHeight(videoHeight);

    // Set canvas width
    canvasRef.current.width = videoWidth;
    canvasRef.current.height = videoHeight;

    const canvasElement = canvasRef.current;
    const canvasCtx = canvasElement.getContext("2d");

    // console.log("res", results);

    // canvasCtx.save();
    // canvasCtx.drawImage(
    //   results.image,
    //   0,
    //   0,
    //   canvasElement.width,
    //   canvasElement.height
    // );

    const divElement = document.getElementsByClassName("trayOnOverLap")[0];

    if (results.multiFaceLandmarks) {
      setFaceDetected(true);
      if (results.multiFaceLandmarks.length > 0) {
        divElement.style.display = "none";
      } else {
        divElement.style.display = "block";
        // divElement.innerHTML = TryOnCardOverLap;
      }
      for (const landmarks of results.multiFaceLandmarks) {
        setLandmarks(landmarks);

        let topPoint = landmarks[10];
        let bottomPoint = landmarks[152];
        let leftPoint = landmarks[234];
        let rightPoint = landmarks[454];

        let width = rightPoint.x - leftPoint.x;
        let height = bottomPoint.y - topPoint.y;

        // let a = { x: leftPoint.x, y: topPoint.y };
        // let b = { x: rightPoint.x, y: topPoint.y };
        let x = leftPoint.x;
        let y = topPoint.y;

        // multiply the width and height by vidoes width and height
        width *= videoWidth;
        height *= videoHeight;
        x *= videoWidth;
        y *= videoHeight;
        // b.x *= videoWidth;
        // b.y *= videoHeight;

        let a = (landmarks[151].y - landmarks[10].y) * videoHeight;
        let fix = (((a / height) * 100 - 8.7) * height) / 50;

        if (fix < 0) {
          height = height - (height * fix) / 25;
          fix = (((a / height) * 100 - 8.7) * height) / 25;
          // y -= fix;
        } else {
          fix = 0;
          fix = (((a / height) * 100 - 8.7) * height) / 35;
          // console.log("fix", fix);
          y -= fix;
        }
        width *= ssRef.current;
        height *= ssRef.current;
        x += xxRef.current * width;
        y += yyRef.current * height;

        // y += height;
        setHeight(height);
        const img = new Image();
        // add image to canvas from public folder
        img.src = selectedImageRef.current;
        if (checkDifference(prevX, prevY, x, y, 5)) {
          prevX = x;
          prevY = y;
          prevWidth = width;
          prevHeight = height;

          canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
          canvasCtx.drawImage(img, x, y, width, height);
        } else {
          canvasCtx.clearRect(0, 0, canvasElement.width, canvasElement.height);
          canvasCtx.drawImage(img, prevX, prevY, prevWidth, prevHeight);
        }
        // connect(canvasCtx, landmarks, Facemesh.FACEMESH_TESSELATION, {
        //   color: "#C0C0C070",
        //   lineWidth: 1,
        // });
        // connect(canvasCtx, landmarks, Facemesh.FACEMESH_RIGHT_EYE, {
        //   color: "#FF3030",
        // });
        // connect(canvasCtx, landmarks, Facemesh.FACEMESH_RIGHT_EYEBROW, {
        //   color: "#FF3030",
        // });
        // connect(canvasCtx, landmarks, Facemesh.FACEMESH_LEFT_EYE, {
        //   color: "#30FF30",
        // });
        // connect(canvasCtx, landmarks, Facemesh.FACEMESH_LEFT_EYEBROW, {
        //   color: "#30FF30",
        // });
        // connect(canvasCtx, landmarks, Facemesh.FACEMESH_FACE_OVAL, {
        //   color: "#E0E0E0",
        // });
        // connect(canvasCtx, landmarks, Facemesh.FACEMESH_LIPS, {
        //   color: "#E0E0E0",
        // });
      }
    }
    // canvasCtx.restore();
  }
  // }

  async function startingFix() {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    const faceMesh = new FaceMesh({
      locateFile: (file) => {
        console.log("file", file);
        // console.log(
        //   "url",
        //   `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`
        // );
        return `https://server.ziyamangalsutra.com/uploads/models/${file}`;
      },
    });

    faceMesh.setOptions({
      maxNumFaces: 1,
      minDetectionConfidence: 0.5,
      minTrackingConfidence: 0.5,
    });

    console.log("cam log 1");

    setTimeout(() => {
      faceMesh.onResults(onResults);
    }, 2000);

    // faceMesh.onResults(onResults);

    // let run = true;
    // while (run) {
    //   console.log("run ", run);
    if (
      typeof webcamRef.current !== "undefined" &&
      webcamRef.current !== null
    ) {
      camera = new cam.Camera(webcamRef.current.video, {
        onFrame: async () => {
          await faceMesh.send({ image: webcamRef.current.video });
        },
        width: 640,
        height: 480,
      });
      console.log("cam log 2");
      camera.start();
      // run = false;
    } else {
      console.log("cam log 3");
      startingFix();
    }
  }
  function handleStop() {
    if (camera) {
      camera.stop();
      console.log("camera stop");
    }
  }
  // setInterval(())
  useEffect(
    () => {
      const faceMesh = new FaceMesh({
        locateFile: (file) => {
          console.log("file", file);
          // console.log(
          //   "url",
          //   `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`
          // );
          return `https://cdn.jsdelivr.net/npm/@mediapipe/face_mesh/${file}`;
        },
      });

      faceMesh.setOptions({
        maxNumFaces: 1,
        minDetectionConfidence: 0.5,
        minTrackingConfidence: 0.5,
      });

      console.log("cam log 1");

      setTimeout(() => {
        faceMesh.onResults(onResults);
      }, 2000);

      // faceMesh.onResults(onResults);

      // let run = true;
      // while (run) {
      //   console.log("run ", run);
      if (
        typeof webcamRef.current !== "undefined" &&
        webcamRef.current !== null
      ) {
        camera = new cam.Camera(webcamRef.current.video, {
          onFrame: async () => {
            await faceMesh.send({ image: webcamRef.current.video });
          },
          width: 640,
          height: 480,
        });
        console.log("cam log 2");
        camera.start();
        // run = false;
      } else {
        console.log("cam log 3");
        startingFix();
      }
    },

    // });
    []
  );

  function handleClick() {
    // console.log(landmarks[0]);
    // a is y distance between 10 and 151 landmarks
    // b is y distance between 9 and 151 landmarks
    let a = (landmarks[151].y - landmarks[10].y) * videoHeight;
    let b = (landmarks[9].y - landmarks[151].y) * videoHeight;
    console.log("a, b", a, b);
    console.log("height", height);
    console.log("devide by height", (a / height) * 100, (b / height) * 100);
    // a, b 15.778055191040039 16.27518653869629
    // height 176.40103340148926
    // devide by height 8.944423332900289 0.092262421737938

    // a, b 8.80162239074707 9.348649978637695
    // height 146.67941093444824
    // devide by height 6.000584768288004 0.06373525717808926

    // a, b 23.724374771118164 23.508281707763672
    // height 168.67072105407715
    // devide by height 14.065496739954023 0.13937381402565258
  }

  const imageStyle = {
    width: "120px",
    height: "120px",
    cursor: "pointer",
    border: "2px solid black",
    borderRadius: "5px",
    // margin: "10px",
  };

  return (
    <Modal
      isOpen={isTry}
      toggle={() => {
        setIsTry(!isTry);
        handleStop();
      }}
      centered
      size="lg"
    >
      <ModalHeader
        className="bg-light p-3"
        toggle={() => {
          setIsTry(false);
          handleStop();
        }}
      >
        Try On
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col lg={2}>
            <img
              style={imageStyle}
              src={`${process.env.REACT_APP_API_URL_ZIYA}/${product.productImage}`}
              alt="Product"
            />
          </Col>
          <Col lg={10}>
            <Row>
              <div className="appvide">
                <canvas
                  // style={{ position: "absolute" }}
                  ref={canvasRef}
                  // width="540"
                  // height="400"
                  className="appcanvas"
                />
                {/* <video
                  className="trayVideoSection"
                  crossOrigin="anonymous"
                  ref={webcamRef}
                  autoPlay
                ></video> */}
                <Webcam
                  ref={webcamRef}
                  style={
                    {
                      // position: "absolute",
                      // marginLeft: "auto",
                      // marginRight: "auto",
                      // left: 0,
                      // right: 0,
                      // textAlign: "center",
                      // zindex: 9,
                      // width: 540,
                      // height: 400,
                    }
                  }
                  className="webCamTrayOn"
                />
                <div className="trayOnOverLap">
                  <TryOnCardOverLap />
                </div>
              </div>
            </Row>

            {/* <Row className="mt-2">
              <Col lg={3} className="m-2 ">
                <label for="customRange2" className="form-label text-dark">
                  Zoom : {ss}
                </label>
                <input
                  name="ss"
                  //   value={values.scaleFactor}
                  value={ss}
                  onChange={handleChangeSF}
                  type="range"
                  className="form-range"
                  min="0.5"
                  max="2"
                  step="0.05"
                  id="customRange2"
                ></input>
              </Col>

              <Col lg={3} className="m-2 ">
                <label for="customRange4" className="form-label text-dark">
                  X : {xx}
                </label>
                <input
                  name="xx"
                  value={xx}
                  type="range"
                  class="form-range"
                  onChange={handleChangeX}
                  min="-1"
                  max="1"
                  step="0.05"
                  id="customRange4"
                ></input>
              </Col>

              <Col lg={3} className="m-2 ">
                <label for="customRange3" className="form-label text-dark">
                  Y : {yy}
                </label>
                <input
                  name="yy"
                  value={yy}
                  type="range"
                  class="form-range text"
                  onChange={handleChangeY}
                  min="-1"
                  max="3"
                  step="0.05"
                  id="customRange3"
                ></input>
              </Col>
            </Row> */}
          </Col>
        </Row>

        {/* {imageLoading && (
          <div className="d-flex justify-content-center mt-2">
            <div className="spinner-border" role="status">
              <span className="sr-only">Loading...</span>
            </div>
            <h5 className="p-2">
              Wait for a few seconds to load Jewellery
            </h5>
          </div>
        )} */}
      </ModalBody>
    </Modal>
  );
};

// export { TryonNew, handleStop };
export default TryonNew2;
