import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import image3 from "../assets/media/slider/web-4.jpg";
import image from "../assets/media/slider/web1.jpg";
import image1 from "../assets/media/slider/web2.jpg";
import image2 from "../assets/media/slider/web3.jpg";
const SliderPart = () => {
  // Custom Next Arrow component
  const NextArrow = ({ onClick }) => (
    <div className="custom-arrow next-arrow" onClick={onClick}>
      <svg
        color="white"
        stroke="white"
        fill="white"
        stroke-width="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="none"
          stroke="#fff"
          stroke-width="2"
          d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"
        ></path>
      </svg>
    </div>
  );

  // Custom Previous Arrow component
  const PrevArrow = ({ onClick }) => (
    <div className="custom-arrow prev-arrow" onClick={onClick}>
      <svg
        stroke="currentColor"
        fill="currentColor"
        stroke-width="0"
        viewBox="0 0 24 24"
        height="1em"
        width="1em"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="none"
          stroke="#fff"
          stroke-width="2"
          d="M6,12.4 L18,12.4 M12.6,7 L18,12.4 L12.6,17.8"
          transform="matrix(-1 0 0 1 24 0)"
        ></path>
      </svg>
    </div>
  );
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    nextArrow: <NextArrow />, // Custom Next Arrow component
    prevArrow: <PrevArrow />, // Custom Previous Arrow component
    responsive: [
      {
        breakpoint: 769, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of slides to show on screens >= 768px wide
        },
      },
      {
        breakpoint: 891, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 1, // Number of slides to show on screens >= 768px wide
        },
      },
      {
        breakpoint: 992, // Adjust the breakpoint as needed
        settings: {
          slidesToShow: 3, // Number of slides to show on screens >= 992px wide
        },
      },
      // Add more breakpoints and settings as needed
    ],
  };

  return (
    <div>
      <Slider {...settings}>
        <div>
          <img src={image3} alt="Image 4" style={{ width: "100%" }} />
        </div>
        <div>
          <img src={image} alt="Image 1" style={{ width: "100%" }} />
        </div>
        <div>
          <img src={image1} alt="Image 2" style={{ width: "100%" }} />
        </div>
        <div>
          <img src={image2} alt="Image 3" style={{ width: "100%" }} />
        </div>
      </Slider>
    </div>
  );
};

export default SliderPart;
